import { template as template_c178fbfee4d14e84b4a13d2bc965902d } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { modifier } from "ember-modifier";
import highlightSearch from "discourse/lib/highlight-search";
export default class HighlightSearch extends Component {
    highlight = modifier((element)=>{
        highlightSearch(element, this.args.highlight);
    });
    static{
        template_c178fbfee4d14e84b4a13d2bc965902d(`
    <span {{this.highlight}}>{{yield}}</span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
