import { template as template_d2c95d5b12874a9b8a077c66d299617f } from "@ember/template-compiler";
const FKText = template_d2c95d5b12874a9b8a077c66d299617f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
