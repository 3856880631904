import { template as template_2a20f165776945b39d80e4d5e01f6417 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_2a20f165776945b39d80e4d5e01f6417(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
