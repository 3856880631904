import { template as template_63c9287daff2403ab1bbfdd4808d4521 } from "@ember/template-compiler";
const FKLabel = template_63c9287daff2403ab1bbfdd4808d4521(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
