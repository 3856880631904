import { template as template_71096df89c074a7daa60a397989a6f18 } from "@ember/template-compiler";
const FKControlMenuContainer = template_71096df89c074a7daa60a397989a6f18(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
